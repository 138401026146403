export default [
    {
        headerName: 'Email',
        field: 'emailAddress',
        width: 300,
        pinned: 'left',
    },
    {
        headerName: 'First Name',
        field: 'firstName',
    },
    {
        headerName: 'Last Name',
        field: 'lastName',
    },
    {
        headerName: 'Client Name',
        field: 'clientName',
        width: 350,
    },
    {
        headerName: 'Client Id',
        field: 'clientId',
        width: 350,
        filter: false,
    },
    {
        headerName: 'SSO Account Id',
        field: 'accountId',
        width: 400,
        filter: false,
    },
    {
        cellRendererFramework: 'RowActionButtons',
        cellRendererParams: {
            linkButtons: [
                {
                    routerLinkIds: ['id'],
                    routeName: 'users-edit',

                    role: 'ROLE_USER_VIEW',
                    btnText: 'Edit',
                    icon: 'fal fa-pencil',
                },
            ],
        },
        width: 100,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
        filter: false,
    },
]
