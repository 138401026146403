<template>
    <child-layout :title="title">
        <ag-grid-server-side
            id="userList"
            :server-side-datasource="serverSideDatasource"
            :column-defs="columnDefs"
        >
        </ag-grid-server-side>
    </child-layout>
</template>

<script>
import AgGridServerSide from '@/components/table/AgGridServerSide'
import columnDefs from '@/helpers/ag-grid/columnDef/userList'
import pagination from '@/helpers/ag-grid/pagination/rest'
import ChildLayout from '@/components/layout/ChildLayout'

export default {
    components: { AgGridServerSide, ChildLayout },
    props: {
        title: {
            type: String,
            default: 'Users',
        },
    },
    data: () => ({ columnDefs }),
    methods: {
        serverSideDatasource: function () {
            const fetch = async (params) => {
                await this.$store.dispatch('user/get', params)

                return this.$store.getters['user/getItems']
            }

            return {
                getRows: async function (params) {
                    const queryPayload = pagination(params)
                    const data = await fetch(queryPayload)

                    params.successCallback(data, data.length)
                },
            }
        },
    },
}
</script>